import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Seamless from "../components/SeamlessEmbed"

const SuppliesForTeachers = () => {
  const FORM =
    "https://secureform.seamlessdocs.com/f/addd7a9078a8ec1c96c287f570da1061?embedded=true"

  return (
    <Layout language="en">
      <SEO
        title="Supplies For Teachers Program at Nalchajian Orthodontics"
        description='Nalchajian Orthodontics will reward one teacher and their classroom by fulfilling a "wish list" of items needed to complete their school year.'
      />

      <div className="supplies-program">
        <div className="supplies__hero">
          <div className="supplies__hero--desktop">
            <img
              className="supplies__hero-img"
              src={`https://res.cloudinary.com/nuvolum/image/upload/f_auto,q_99/v1/Programs/2022-sft-hero-image`}
              alt="program banner"
            />
          </div>
          <div className="supplies__hero--mobile">
            <img
              className="supplies__hero-img"
              src={`https://res.cloudinary.com/nuvolum/image/upload/f_auto,q_99/v1/Programs/2022-sft-logo`}
            />
            <img
              className="supplies__hero-img"
              src={`https://res.cloudinary.com/nuvolum/image/upload/f_auto,q_99/v1/Programs/2022-sft-hero-image-mobile`}
            />
          </div>
          <h1 className="supplies__heading">
            Giving Teachers the Supplies They Need
          </h1>
        </div>

        <div className="supplies__section">
          <div className="supplies__about-grid">
            <div className="supplies__about-grid--content">
              <p>
                The team at Nalchajian Orthodontics is excited to announce our
                second Supplies For Teachers program. We are so grateful for the
                educators in our community, and we’re rewarding one teacher and
                their classroom by fulfilling a “wish list” of items needed to
                complete their school year.
              </p>
              <h2 className="supplies__subheading">
                Support Our Community’s Educators
              </h2>
              <p>
                Teachers make a tremendous impact, and Supplies For Teachers is
                a fantastic opportunity to recognize a hardworking teacher.
                After the application period, we will select one teacher and
                will donate the resources needed to fulfill their classroom’s
                “wish list.” We look forward to providing one teacher and their
                students with the resources they need for a positive and
                successful school year!
              </p>

              <p>
                You can help fulfill more wish lists this year! Drop off any
                new, unused supplies at our office:
              </p>
              <ul className="supplies__grid--2">
                <li>Pens (blue, black, red)</li>
                <li>Pencils</li>
                <li>Crayons</li>
                <li>Colored pencils</li>
                <li>Markers</li>
                <li>Highlighters</li>
                <li>Erasers</li>
                <li>Spiral-bound notebooks</li>
                <li>Composition books</li>
                <li>Folders (pocket folders, file folders)</li>
                <li>Glue sticks/liquid glue</li>
                <li>Computer accessories (headphones, earbuds)</li>
                <li>Tissues </li>
                <li>Hand sanitizer</li>
                <li>Disinfecting wipes</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="supplies__section">
          <div className="supplies__info-box">
            <p className="supplies__subheading">To Apply:</p>
            <ul>
              <li>Fill out the application.</li>
              <li>
                Submit completed applications now through October 1, 2024.
              </li>
            </ul>
            <p>
              Teachers can apply themselves, or you can submit an application to
              nominate a teacher.{" "}
            </p>
          </div>
        </div>

        {!FORM && (
          <div className="supplies__section">
            <p className="has-text-centered">
              <strong>
                Thank you for your interest in our Supplies For Teachers
                program. Our 2024 application period is closed, but stay tuned
                on our Facebook page to hear about the voting period!
              </strong>
            </p>
          </div>
        )}

        {FORM && (
          <>
            <div className="supplies__apply-banner">
              <p>SUBMIT APPLICATION</p>
            </div>

            <div className="supplies__section supplies__form">
              <Seamless src={FORM} />
            </div>
          </>
        )}

        <div className="supplies__section">
          <p>
            Stay tuned to this page,{" "}
            <a
              href="https://www.facebook.com/Nalchajian.Orthodontics"
              title="Follow us on Facebook"
              target="_blank">
              Facebook
            </a>
            , and{" "}
            <a
              href="https://www.instagram.com/nalchajianorthodontics/"
              target="_blank"
              title="Follow us on Instagram">
              Instagram
            </a>{" "}
            for program updates!
          </p>
          <p>
            <i>
              Learn more about{" "}
              <Link to="/" title="Learn more about Nalchajian Orthodontics">
                Nalchajian Orthodontics
              </Link>{" "}
              and the{" "}
              <Link to="/services/" title="Learn more about our services">
                services
              </Link>{" "}
              we provide, including{" "}
              <Link to="/braces-fresno-ca/" title="Learn more about braces">
                braces
              </Link>{" "}
              and{" "}
              <Link
                to="/invisalign-fresno-clovis-ca/"
                title="Learn more about Invisalign">
                Invisalign<sup>®</sup>
              </Link>
              .
            </i>
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default SuppliesForTeachers
